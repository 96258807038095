<section id="purchase-information" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>INFORMÁCIE O NÁKUPE</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-plus-img"
          src="/assets/images/pages/purchase-information/novo-c-termekek.webp"
          alt="Novo C Plus"
        />
      </div>
      <div class="col-md-6" style="margin-top: 2rem">
        <h2>DOSTUPNÉ V LEKÁRŇACH.</h2>
      </div>
    </div>
  </div>
</section>

<section id="where" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>KDE ZÍSKAŤ NOVO C?</h2>
        <p>
          Výrobky sú dostupné v lekárňach, bylinných špecializovaných obchodoch
          a drogériách.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="packaging" class="pb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">Dostupné v lekárňach.</h2>
          <h2>Balenie a odporúčaná maloobchodná cena:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus 30</th>
              <td>11 €</td>
            </tr>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus 60</th>
              <td>17 €</td>
            </tr>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus 90</th>
              <td>22 €</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/main/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-plus-forte/novo-c-plus-forte.png"
          alt="Novo C plus forte"
        />
      </div>
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">Dostupné v lekárňach.</h2>
          <h2>Balenie a odporúčaná maloobchodná cena:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus forte 60 db</th>
              <td>22 €</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">Dostupné v lekárňach.</h2>
          <h2>Balenie a odporúčaná maloobchodná cena:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> komplex 30</th>
              <td>13 €</td>
            </tr>
            <tr>
              <th>Novo C<sup>&reg;</sup> komplex 60</th>
              <td>22 €</td>
            </tr>
            <tr>
              <th>Novo C<sup>&reg;</sup> komplex 90</th>
              <td>27 €</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/main/novo-c-komplex.png"
          alt="Novo C komplex"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-komplex-forte/novo-c-komplex-forte.png"
          alt="Novo C komplex forte"
        />
      </div>
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">Dostupné v lekárňach.</h2>
          <h2>Balenie a odporúčaná maloobchodná cena:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> komplex forte</th>
              <td>23 €</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">Dostupné v lekárňach.</h2>
          <h2>Balenie a odporúčaná maloobchodná cena:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus 1000 mg 150 ml</th>
              <td>17 €</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-plus-1000mg/novo-c-plus-1000mg.png"
          alt="Novo C plus 1000 mg"
        />
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">Dostupné v lekárňach.</h2>
          <h2>Balenie a odporúčaná maloobchodná cena:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> kids</th>
              <td>13 €</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/novo-c-kids/novo-c-kids.png"
          alt="Novo C kids"
        />
      </div>
    </div>
  </div>
</section>
<section class="footer-bg">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2>Odporúčané internetové obchody:</h2>
        <div class="pharmacies">
          <div class="pharmacy">
            <a
              href="https://www.viplekaren.sk/produkt/novo-c-komplex-lipozomalny-vitamin-c-vitamin-d3-zinok-kapsuly-1x90-ks/"
              target="_blank"
            >
              <img
                src="/assets/images/pages/purchase-information/cropped-logos/vip-lekaren-logo.webp"
                alt=""
              />
              <span>VIP lekáreň</span>
            </a>
          </div>
          <div class="pharmacy">
            <a
              href="https://www.drmax.sk/novo-c-komplex-x45304"
              target="_blank"
            >
              <img
                src="/assets/images/pages/purchase-information/cropped-logos/drmax.webp"
                alt=""
              />
              <span>Dr. Max</span>
            </a>
          </div>
          <div class="pharmacy">
            <a
              href="https://www.vasalekaren.sk/novo-c-komplex-p19455"
              target="_blank"
            >
              <img
                src="/assets/images/pages/purchase-information/cropped-logos/vaša lek.webp"
                alt=""
              />
              <span>Vaša lekáreň</span>
            </a>
          </div>
          <div class="pharmacy">
            <a
              href="https://etabletka.sk/obchod/novo-c-komplex-lipozomalny-vitamin-c-vitamin-d3-zinok-60-cps/"
              target="_blank"
            >
              <img
                src="/assets/images/pages/purchase-information/cropped-logos/etabletka-logo.webp"
                alt=""
              />
              <span>eTabletka</span>
            </a>
          </div>
          <div class="pharmacy">
            <a
              href="https://www.pilulka.sk/novo-c-komplex-lipozomalny-vitamin-c-vitamin-d3-zinok-kapsuly-1x90-ks"
              target="_blank"
            >
              <img
                src="/assets/images/pages/purchase-information/cropped-logos/pilulka-logo.webp"
                alt=""
              />
              <span>Pilulka</span>
            </a>
          </div>
          <div class="pharmacy">
            <a
              href="https://www.benulekaren.sk/novo-c-komplex-lipozomalny-vitamin-c-vitamin-d3-zinok-90-kapsul"
              target="_blank"
            >
              <img
                src="/assets/images/pages/purchase-information/cropped-logos/benu.webp"
                alt=""
              />
              <span>Benu</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
