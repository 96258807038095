<section id="absorption" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <h1>ABSORPCIA</h1>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Z vitamínov je vitamín C jedným z hlavných „spojencov“ nášho
            imunitného systému. Aj tak plytváme?! Odpoveď je ÁNO!
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>VÁŽNE OBMEDZENIA ABSORPCIE</h2>
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <h3>
            Od objavenia vitamínu C sa uskutočnilo množstvo štúdií, ktoré
            zisťovali, ako efektívne je telom absorbovaný.
          </h3>
          <h3>Podľa výskumov:</h3>
          <ul>
            <li>
              Pretože je vitamín C rozpustný vo vode, telo si ho môže ukladať
              iba v obmedzenej miere.
            </li>
            <li>
              Organizmus vie prijať iba 200 mg obyčajného vitamínu C, zvyšok sa
              vylúči nezužitkovaný z organizmu tráviacim traktom.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <img
            src="/assets/images/pages/absorption/felszivodas.png"
            alt="Liposomálna"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <h3>
            Časť absorpcie vitamínu C sa deje v ústnej sliznici a v žalúdku, ale
            vnajväčšom množstve v tenkom čreve.
          </h3>
          <p>
            Vitamín C má toľko času na vstrebávanie, koľkočasu prechádza
            tráviacim systémom a vylučuje sa.
          </p>
          <p>
            Ovplyvňuje dobu vstrebavania vitamínu C v tráviacom trakte a,
            saturáciu žalúdka v čase príjmu a aktuálny metabolizmus človeka,
            preto sa účelne podáva v časových intervaloch.
          </p>
          <h4>Dĺžka pobytu</h4>
          <ul>
            <li>v žalúdku 0,5 - 3 hodiny</li>
            <li>v tenkom čreve 6 - 8 hodín po požití.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>ČO SA STANE S NEPOUŽITÝM VITAMÍNOM C?</h2>
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Nevstrebaný vitamín C sa dostáva do hrubého čreva. Časté sú
            nepríjemné vedľajšie účinky, ako je pálenie záhy, zvýšené močenie
            alebo dokonca hnačka. Z hrubého čreva sa vitamín C vylučuje priamo
            do stolice. Ukazuje sa tiež, že užívanie vyšších dávok spôsobí, že
            náš moč bude obzvlášť bohatý na vitamín C, zatiaľ čo naše telá budú
            naďalej „hladovať“ po vitamíne C. A veľké množstvo vyčerpaného
            vitamínu C môže dokonca podporovať tvorbu obličkových kameňov.
          </p>
          <h3>ČO ZNAMENÁ AKTÍVNA A PASÍVNA DOPRAVA?</h3>
          <p>
            Vitamín C sa môže absorbovať aktívnym alebo pasívnym transportom v
            ktorejkoľvek fáze tráviaceho systému.
          </p>
          <p>
            Aktívny transport: účinné látky vstupujú do krvi sodíkovými kanálmi
            a transportérmi glukózy. Priepustnosť a výkonnosť sodíkových kanálov
            sú obmedzené. Energeticky náročný proces.
          </p>
          <p>
            Pasívny transport: účinné látky vstupujú do krvi cez membrány buniek
            črevnej steny. Nevyžaduje si to investíciu do energie zo strany
            organizmu.
          </p>
          <img src="/assets/images/pages/absorption/abs1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <img src="/assets/images/pages/absorption/feltoltodes.jpeg" alt="" />
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <h3>PREČO JE VÄČŠINA KLASICKÉHO VITAMÍNU C VYLÚČENÁ Z TELA?</h3>
          <p>
            Časť z molekúl vitamínu Cie už po požití absorbovaná v žalúdku
            aktívnym transportom cez obsadené sodíkové kanály.
          </p>
          <p>
            Keď sa kanál naplní, nie je schopný dodať viac liečiva do krvi,
            takže voľné molekuly vitamínu C cestujú s jedlom cez
            gastrointestinálny trakt do tenkého čreva.
          </p>
          <p>
            Počet sodíkových a glukózových kanálov je obmedzený. Keď sa nasýtia
            nepríjmu viac účinných látok, takže sa vylučujú stolicou. Keď sa
            molekula vitamínu C stretne s voľným transportérom na črevnej stene,
            absorbuje sa, ak nie je vylúčená z tela.
          </p>
          <p>
            To je dôvod, prečo sa väčšina tradičného vitamínu C vylučuje z tela
            bez toho, aby sa absorbovala a využila.
          </p>
          <img src="/assets/images/pages/absorption/abs2.png" alt="" />
          <h3>AKO SA ABSORBUJE LIPOZOMÁLNY VITAMÍN C?</h3>
          <p>
            Niektoré z molekúl vitamínu C uzavretých v lipozómoch tiež prenášajú
            svoj obsah v žalúdku ich väzbou na bunky membránovou fúziou. Táto
            fúzia je okamžitý, minútou meraný proces, ktorý rýchlo zvyšuje
            hladinu vitamínu C v krvi a sprístupňuje ich telu.
          </p>
          <img src="/assets/images/pages/absorption/abs3.png" alt="" />
          <p>
            Väčšina vitamínu C zapuzdreného v lipozóme putuje zo žalúdka cez
            tráviaci trakt do tenkého čreva a po celej jeho dĺžke sa liečivo
            transportuje kontinuálne v spojení s bunkami. Ako lipozóm postupuje,
            vstrebáva sa a uvoľňuje svoj obsah do bunky, čím zaisťuje dlhodobú a
            kontinuálnu absorpciu. Lipozomálny roztok nám teda poskytuje vitamín
            C vo väčších množstvách, nepretržite a v kratšej dobe.
          </p>
          <h3>ČO ZNAMENÁ, POSTUPNÉ UVOĽŇOVANIE</h3>
          <p>
            Dlohotrvajúci účinok v porovnaní s bežnou formou vitamínu C znamená,
            že obsah účinnej látky sa uvoľňuje kontinuálne po dlhšiu dobu tým,
            že sa dokáže postupne absorbovať v zažívacom trakte. Cieľom je
            zabezpečiť rovnomernejšiu hladinu účinnej látky v krvi, čím sa zvýši
            účinnosť a znížia vedľajšie účinky. V prípade lipozómov sa dá
            očakávať nielen dlhšie uvoľňovanie liečiva, ale v prípade vitamínu C
            sa vďaka špecifickému absorpčnému mechanizmu kyseliny askorbovej
            spotrebuje viac lipozomálneho vitamínu C, a následne sa dajú
            dosiahnuť vyššie plazmatické hladiny vitamínu C ako pri bežných
            formách.
          </p>
          <img src="/assets/images/pages/absorption/abs4.png" alt="" />
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>ČO JE LIPOSOMÁLNA TECHNOLÓGIA A ČO SÚ LIPOZÓMY?</h2>
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Lipozomálna technológia je jedným z najnovších technologických
            objavov, ktoré používajú lekárski vedci na dodávanie dôležitých
            liekov do správnych orgánov. Je to tak preto, lebo táto technológia
            umožňuje cielené doručovanie v rámci organizmu.
          </p>
          <p>
            Lipozomálny vitamín C nie je nič iné ako vitamín C a prírodné látky,
            tzv šikovná kombinácia fosfolipidov. Počas výrobného procesu je
            vitamín C zabalený do obalu podobnému bunkovej membráne a vytvára
            gulôčky a lipozómy veľkosti nano. Tento obal poskytuje ochrannú
            vrstvu pre vitamín C počas tráviaceho procesu a umožňuje mu fúziu s
            bunkami. Pomocou prietoku krvi sa dostáva kdekoľvek v tele, čo
            umožňuje efektívne vstrebávanie a využitie.
          </p>
          <h3>ČO ZNAMENÁ BIO-VYUŽITIE?</h3>
          <p>
            Biologická dostupnosť je rýchlosť a doba, akou sa účinná zložka
            uvoľňuje z liečiva a sprístupňuje v krvi. Dáta sú vložené do grafu a
            potom sú porovnané oblasťami pod krivkami.
          </p>
          <p>
            Biologická dostupnosť lipozomálneho vitamínu C je dvakrát vyššia ako
            biologická dostupnosť bežných vitamínov C. To znamená, že za
            jednotkový čas sa môže vstrebať väčšie množstvo, môže zostať v krvi
            dlhší čas, takže plocha pod krivkou je 2x väčšia ako plocha
            tradičného vitamínu C.
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <div class="content-title">
          <h2>TAK ÚČINNÉ, ŽE AJ NIŽŠIA DÁVKA JE DOSTATOČNÁ!</h2>
        </div>
      </div>
      <div class="col-md-8">
        <div class="content">
          <p>
            Ďalšou výhodou vynikajúceho využitia je, že dávku je možné znížiť,
            pretože účinná látka sa dostane tam, kde skutočne potrebuje
            pracovať. Už jedna kapsula Novo C plus<sup>&reg;</sup> 300 mg denne
            prispieva k: účinnej podpore imunitného systému, znižovaniu únavy a
            vyčerpanosti, zdraviu pokožky, ďasien, kostí a chrupaviek.
          </p>
          <h3>MALÉ, ALE OSVEDČENÉ A VEĽMI SILNÉ!</h3>
          <p>
            Klinická štúdia z roku 2015 potvrdila, že Novo C plus<sup
              >&reg;</sup
            >
            sa vstrebáva v čreve a vstupuje vo väčšej miere do obehu.
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C Plus u zdravých
            dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
