<section id="hero">
  <img src="/assets/images/pages/main/hero-new-new.jpg" alt="Novo C" />
</section>

<section id="scientific-background" class="mt-4 mb-5">
  <div class="home-title text-center">
    <span> Vedecké pozadie </span>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div id="liposomal-technology">
          <a [routerLink]="['/liposomalna-technologia']">
            <img
              src="/assets/images/pages/main/liposomal-technology.jpg"
              alt="Liposzómális technológia"
              id="liposomal-technology-img"
            />
            <h2>ČO SÚ LIPOZÓMY?</h2>
          </a>
          <p>
            Lipozomálny vitamín C Novo C je novou generáciou vitamínu C s
            najefektívnejšou absorbciou vitamínu C! Spoznáte viac ako sú
            tradičné vitamíny C a viac ako sú vitamíny C s postupným
            uvoľňovanim.
          </p>
          <p>
            Lipozomálny vitamín C nie je nič iné ako vitamín C a prírodné látky,
            tzv šikovná kombinácia fosfolipidov. Počas výrobného procesu je
            vitamín C zabalený do obalu podobnému bunkovej membráne a vytvára
            guľôčky a lipozómy veľkosti nano. Tento obal poskytuje ochrannú
            vrstvu pre vitamín C počas tráviaceho procesu a umožňuje mu fúziu s
            bunkami. Pomocou prietoku krvi sa dostáva kdekoľvek do tela, čo
            umožňuje efektívne vstrebávanie a využitie.
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <div id="absorption">
          <a [routerLink]="['/absorpcia']">
            <img
              src="/assets/images/pages/main/absorption.jpg"
              alt="Felszívódás"
              id="absorption-img"
            />
            <h2>ABSORPCIA</h2>
          </a>
          <p>
            Z vitamínov je vitamín C jedným z hlavných „spojencov“ nášho
            imunitného systému. Aj tak ním plytváme? Odpoveď je ÁNO!
          </p>
          <p>
            Od objavenia vitamínu C sa uskutočnilo množstvo štúdií, ktoré
            zisťovali, ako efektívne je telom absorbovaný.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="products" class="my-5">
  <div class="products-container">
    <div class="row">
      <div class="col-md-4">
        <div id="novo-c-multivitamin" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-multivitamin']">
            <img
              src="/assets/images/pages/main/novo-c-multivitamin.webp"
              alt="Novo C multivitamin"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-multivitamin']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>multivitamin</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-multivitamin']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-kids" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-kids']">
            <img
              src="/assets/images/pages/main/novo-c-kids.png"
              alt="Novo C kids"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-kids']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>kids</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-kids']">Čítaj viac</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-plus" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus']">
            <img
              src="/assets/images/pages/main/novo-c-plus.png"
              alt="Novo C plus"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus']">Čítaj viac</a>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div id="novo-c-plus-liquid" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus-1000mg']">
            <img
              src="/assets/images/pages/main/novo-c-plus-1000mg.png"
              alt="Novo C plus 1000 mg"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus-1000mg']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus 1000 mg</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus-1000mg']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div> -->
      <div class="col-md-4">
        <div id="novo-c-plus-forte" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus-forte']">
            <img
              src="/assets/images/pages/main/novo-c-plus-forte.png"
              alt="Novo C plus forte"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus-forte']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus forte</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus-forte']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-komplex" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-komplex']">
            <img
              src="/assets/images/pages/main/novo-c-komplex.png"
              alt="Novo C komplex"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-komplex']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>komplex</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-komplex']">Čítaj viac</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-komplex-forte" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-komplex-forte']">
            <img
              src="/assets/images/pages/main/novo-c-komplex-forte.png"
              alt="Novo C komplex forte"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-komplex-forte']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>komplex forte</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-komplex-forte']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="csuszda">
  <div class="img-box">
    <a routerLink="/nase-produkty/novo-c-kids">
      <img src="/assets/images/pages/main/csuszda.webp" alt="Kids" />
      <div class="content">
        <h3>Novo C<sup>&reg;</sup> detský lipozomálny vitamín C od 1 roka</h3>
        <h4>
          Novo C<sup>&reg;</sup> kids lipozomálny vitamín C je nová generácia
          vitamínu C.
        </h4>
        <p>
          Dokáže viac ako tradičné vitamíny C a viac ako vitamín s postupným
          uvoľňovaním C.<sup>1.</sup>
        </p>
        <p>
          Tekuté zloženie umožňuje jednoduché dávkovanie a jeho lahodná malinová
          príchuť sa rýchlo stane obľúbenou u detí.
        </p>
      </div>
    </a>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1>ZLOŽKY</h1>
        <h2>
          VYROBENÉ S PRÍRODNÝMI ZLOŽKAMI<br />Obsahuje prírodný šípkový extrakt
          a citrusové bioflavonoidy.<br />A LIPOZOMÁLNY VITAMÍN D A LIPOZOMÁLNY
          ZINOK.
        </h2>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-md-4">
        <div class="vitamin text-center">
          <a [routerLink]="['/vitamin-c']">
            <img
              src="/assets/images/pages/main/c-vitamin.png"
              alt="C-vitamin"
            />
            <h3>Vitamín C</h3>
            <p>
              Z vitamínov je vitamín C jedným z hlavných „spojencov“ nášho
              imunitného systému. Aj napriek tomu ním plytváme.
            </p>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="vitamin text-center">
          <a [routerLink]="['/vitamin-d']">
            <img
              src="/assets/images/pages/main/d-vitamin.png"
              alt="D-vitamin"
            />
            <h3>Vitamín D3</h3>
            <p>
              V posledných rokoch boli objavené účinky vitamínu D na imunitný
              systém spolu s množstvom ďalších prospešných vlastností.
            </p>
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="vitamin text-center">
          <a [routerLink]="['/zinc']">
            <img src="/assets/images/pages/main/zinc.png" alt="ZN-vitamin" />
            <h3>ZINOK</h3>
            <p>
              Zinok je minerál, ktorý sa odporúča uživať v malom množstve, ale
              je nevyhnutný pre normálne fungovanie nášho tela a imunitného
              systému.
            </p>
          </a>
        </div>
      </div>
    </div>
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Randomizovaná porovnávacia štúdia na stanovenie biologickej
            dostupnosti doplnku Novo C® Plus s vitamínom C u zdravých
            dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back"></div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
