<section id="novo-c-plus-liquid" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> plus 1000 mg</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-plus-img"
          src="/assets/images/pages/novo-c-plus-1000mg/novo-c-plus-1000mg.png"
          alt="Novo C Plus"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> plus 1000 mg sa odporúča užívať na:</h2>
        <ul>
          <li>správne fungovanie imunitného systému</li>
          <li>na zníženie stavu únavy a vyčerpanosti</li>
          <li>normálne fungovanie nervového systému</li>
          <li>skrátenie dĺžky ochorenia v prípade prechladnutia</li>
          <li>udržanie normálnej psychickej pohody</li>
          <li>
            normálne fungovanie metabolických procesov produkujúcich energiu
          </li>
          <li>
            na podporu tvorby kolagénu a tým pre udržanie normálneho stavu a
            funkcie pokožky, zubov a ďasien, kostí a chrupaviek, ako aj krvných
            ciev
          </li>
          <li>zvýšenie absorpcie železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="content">
          <p>
            Novo C<sup>&reg;</sup> plus 1000 mg vitamínu C je tekutý doplnok
            výživy obsahujúci lipozomálny vitamín C.
          </p>
          <p>
            1000 mg vitamínu C v 5 ml dennej dávky. Určené pre ľudí so zvýšenými
            požiadavkami na vitamín C.
          </p>
          <a
            [routerLink]="['/liposomalna-technologia']"
            routerLinkActive="router-link-active"
            class="btn"
            >Ďalšie</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="/assets/images/pages/novo-c-plus/narancs.png"
          alt="C-vitamin"
        />
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="table-div">
          <h2>Účinná látka a * NRV% v odporúčanej dennej dávke</h2>
          <table>
            <tbody>
              <tr>
                <th colspan="3">V 5 ml</th>
              </tr>
              <tr>
                <td>Účinná látka</td>
                <td>Účinná látka</td>
                <td>*NRV%</td>
              </tr>
              <tr>
                <td>Vitamín C</td>
                <td>1000 mg</td>
                <td>1250%</td>
              </tr>
              <tr>
                <th colspan="3">V 10 ml</th>
              </tr>
              <tr>
                <td>Účinná látka</td>
                <td>Účinná látka</td>
                <td>*NRV%</td>
              </tr>
              <tr>
                <td>Vitamín C</td>
                <td>2000 mg</td>
                <td>2500%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content">
          <p>*NRV%= Referenčná hodnota denného príjmu pre dospelých.</p>
          <p>
            <b>Zloženie:</b> Čistá voda, Rastlinný glycerín, Vitamín: Vitamín C:
            L-askorbát sodný, Regulátor kyslosti: kyselina mliečna, Emulgátor:
            sójový lecitín, Vôňa: prírodná pomarančová a mangová aróma.
          </p>
          <p>
            Bez cukru, bez laktózy, bezlepkový, bez konzervantov, nealkoholické,
            pro vegetariany.
          </p>
          <a
            [routerLink]="['/informacie-o-nakupe']"
            routerLinkActive="router-link-active"
            class="btn"
            >INFORMÁCIE O KÚPE</a
          >
        </div>
      </div>
      <div class="col-lg-5 flex-center">
        <img
          src="/assets/images/pages/novo-c-plus-1000mg/novo-c-plus-1000mg.png"
          alt="Novo C plus 1000mg"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-plus-1000mg/novo-c-plus-1000mg-title.png"
          alt="Novo C plus 1000mg"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>Informácie o produkte:</h2>
        <p>
          <b>Novo C<sup>&reg;</sup> plus 1000 mg zaručuje:</b>
        </p>
        <ul>
          <li>obsah výlučne geneticky nemodifikovaných zložiek,</li>
          <li>prírodné farbivo získané z paradajkového extraktu (lykopén),</li>
          <li>vyrobené bez konzervačných látok.</li>
        </ul>
        <p>
          <b
            >Neobsahuje žiadne zbytočné prísady, ktoré by zaťažovali
            organizmus.</b
          >
        </p>
        <p>
          Moderný výrobný proces produktu zodpovedá požiadavkám správnej
          výrobnej praxe (GMP).
        </p>
        <div class="icons">
          <div class="img">
            <img src="/assets/images/pages/ikonok-cukormentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-laktozmentes.png" alt="" />
          </div>
          <div class="img">
            <img
              src="/assets/images/pages/ikonok-tartositoszermentes.png"
              alt=""
            />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-glutenmentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-alkoholmentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-vegan.png" alt="" />
          </div>
          <!-- <img
            src="/assets/images/pages/novo-c-plus/GMO-mentes.png"
            alt="GMO mentes"
            width="110px"
          />
          <img
            src="/assets/images/pages/novo-c-plus/GMO-free.png"
            alt="GMO mentes"
            width="110px"
          /> -->
        </div>
      </div>
      <div class="col-md-6">
        <h2>Dávkovanie:</h2>
        <p>
          <b
            >Pre dospelých sa môže užívať 1 - 2 krát denne, odmerané po značku 5
            ml (1 000 mg vitamínu C) v dodávanom dávkovači, kedykoľvek počas dňa
            bez riedenia alebo zmiešať s vodou alebo džúsom.</b
          >
        </p>
        <h2>Skladovanie:</h2>
        <p>
          <b
            >Skladujte na chladnom a suchom mieste chránenom pred sálavým teplom
            a priamym slnečným žiarením. Po otvorení je možné spotrebovať do 120
            dní. Neprekračujte odporúčanú spotrebu! Doplnok výživy nenahrádza
            vyváženú zmiešanú stravu a zdravý životný štýl. Uchovávajte výrobok
            mimo dosahu detí! Výrobok sa vyrába v certifikovanom výrobnom
            závode, ktorý spĺňa výrobné požiadavky EÚ.</b
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PREČO JE Novo C<sup>&reg;</sup> plus 1000 mg ŠPECIÁLNE?</h2>
        <p>
          Novo C<sup>&reg;</sup> plus 1000 mg doplnok výživy obsahuje vitamín C
          v lipozomálnej forme. Vďaka inovatívnej technológii je vitamín C
          obklopený prírodnou fosfolipidovou vrstvou, ktorá tiež vytvára
          membrány ľudských buniek. Toto ochranné puzdro umožňuje vitamínu C
          ľahšie vstúpiť do buniek. V Novo C<sup>&reg;</sup> plus 1000 mg
          lipozomálnej tekutiny je vitamín C veľmi dobre využitý a poskytuje
          telu vysoké hladiny vitamínu C. Vitamín C prispieva k:
        </p>
        <ul>
          <li>pre normálne fungovanie imunitného systému,</li>
          <li>chrániť bunky pred oxidačným stresom,</li>
          <li>na zníženie únavy a vyčerpanosti,</li>
          <li>pre normálne fungovanie nervového systému,</li>
          <li>pre normálne metabolické procesy produkujúce energiu.</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2>ČO SÚ LIPOZÓMY?</h2>
        <p>
          Lipozomálny vitamín C nie je nič iné ako vitamín C a prírodné látky,
          tzv šikovná kombinácia fosfolipidov. Počas výrobného procesu je
          vitamín C zabalený do obalu podobnému bunkovej membráne a vytvára
          guľôčky a lipozómy veľkosti nano. Tento obal poskytuje ochrannú vrstvu
          pre vitamín C počas tráviaceho procesu a umožňuje mu fúziu s bunkami.
          Pomocou prietoku krvi sa dostáva kdekoľvek do tela, čo umožňuje jeho
          efektívne vstrebávanie a využitie v organizme.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>
          AKO SA ABSORBUJE LIPOZOMÁLNY VITAMÍN C Z Novo C<sup>&reg;</sup> plus
          1000 mg?
        </h2>
        <p>
          Mäkká želatínová kapsula s vitamínom C sa rozpúšťa v žalúdku a
          uvoľňujú sa z nej lipozómy. Niektoré z nich pripojením sa na bunky
          žalúdka pomocou membránovej fúzie zvyšujú obsah vitamínu C v krvi a
          rýchlo ho dodávajú telu. Väčšina lipozómov sa dostane do tenkého
          čreva, odkiaľ poskytuje postupné a dlhotrvajúce doplnenie vitamínu C
          do orgánov a systémov. Lipozomálny roztok nám teda poskytuje vitamín C
          vo väčších množstvách, nepretržite a v kratšej dobe.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ČO JE MEMBRÁNOVÁ FÚZIA?</h2>
        <p>
          Bunky a lipozóm sú ohraničené rovnakou dvojitou lipidovou vrstvou,
          ktorá sa navzájom priťahuje.
        </p>
        <p>
          Lipozómy dodávajú liečivo do buniek membránovou fúziou, takže množstvo
          uvoľňovania nie je obmedzené.
        </p>
        <div class="img-box">
          <img src="/assets/images/pages/membran.png" alt="membran" />
        </div>
        <p>
          Takže sa nemusíme báť, že močom vylúčime veľa vitamínu C, pretože
          vitamín C sa v tele využíva a nevylučuje močom. Takto môže skutočne
          mať priaznivý vplyv na imunitný systém. A nezaťažuje to ani tráviaci
          systém: napríklad sa u vás neobjaví pálenie záhy alebo zvýšené
          močenie.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>TAK ÚČINNÝ VIT C, ŽE NIŽŠIA DÁVKA JE DOSTATOČNÁ!</h2>
        <p>
          Ďalšou výhodou vynikajúceho využitia je, že dávku je možné znížiť,
          pretože účinná látka sa dostane tam, kde skutočne potrebuje pracovať.
          Už jedna kapsula Novo C<sup>&reg;</sup> plus 1000 mg denne prispieva
          k: účinnej podpore imunitného systému, znižovaniu únavy a
          vyčerpanosti, zdraviu pokožky, ďasien, kostí a chrupaviek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>MALÉ, ALE OSVEDČENÉ A VEĽMI SILNÉ!</h2>
        <p>
          Klinická štúdia z roku 2015 potvrdila, že Novo C<sup>&reg;</sup> plus
          1000 mg sa absorbuje v čreve a vstupuje do obehu vo väčšej miere. Telo
          má teda počas dňa k dispozícii väčšie množstvo vitamínu C, čím sa
          lepšie prispôsobuje zvýšeným potrebám tela.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>POSKYTUJE DLHODOBÝ ÚČINOK!</h2>
        <p>Vitamín C užívajte nepretržite minimálne 12 hodín.</p>
        <p>
          V prípade lipozomálneho vitamínu C sa dá očakávať nielen dlhšie
          uvoľňovanie liečiva, ale vďaka absorpčnému mechanizmu lipozómu je
          možné získať viac výhod, pri rovnakom príjme liečiva sa dajú dosiahnuť
          vyššie plazmatické hladiny.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENÉ S PRÍRODNÝMI ZLOŽKAMI</h2>
        <p>Obsahuje prírodný šípkový extrakt a citrusové bioflavonoidy.</p>
      </div>
    </div>
  </div>
</section>

<section id="csuszdas-ar">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="table-div">
          <h2>
            DOSTUPNÉ V LEKÁRŇACH.<br />BALENIE A ODPORÚČANÁ MALOOBCHODNÁ CENA:
          </h2>
          <table>
            <tbody>
              <tr>
                <th>Novo C<sup>&reg;</sup> plus 1000 mg 150 ml</th>
                <td>17 €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5">
        <img src="/assets/images/pages/novo-c-plus-1000mg/novo-c-plus-1000mg.png" alt="" />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C<sup>&reg;</sup>
            plus 1000 mg u zdravých dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
