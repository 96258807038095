import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-novo-c-komplex-forte",
  templateUrl: "./novo-c-komplex-forte.component.html",
  styleUrls: ["./novo-c-komplex-forte.component.scss"],
})
export class NovoCKomplexForteComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle("Novo C® komplex forte");
  }
}
