import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { ProductsComponent } from "./pages/products/products.component";
import { NovoCPlusComponent } from "./pages/products/novo-c-plus/novo-c-plus.component";
import { NovoCKomplexComponent } from "./pages/products/novo-c-komplex/novo-c-komplex.component";
import { NovoCImmunComponent } from "./pages/products/novo-c-immun/novo-c-immun.component";
import { LiposomalTechnologyComponent } from "./pages/liposomal-technology/liposomal-technology.component";
import { CVitaminComponent } from "./pages/ingredients/c-vitamin/c-vitamin.component";
import { DVitaminComponent } from "./pages/ingredients/d-vitamin/d-vitamin.component";
import { ZincComponent } from "./pages/ingredients/zinc/zinc.component";
import { RosehipsComponent } from "./pages/ingredients/rosehips/rosehips.component";
import { CitrusBioflavonoidComponent } from "./pages/ingredients/citrus-bioflavonoid/citrus-bioflavonoid.component";
import { PharmacyFinderComponent } from "./pages/pharmacy-finder/pharmacy-finder.component";
import { AbsorptionComponent } from "./pages/absorption/absorption.component";
import { PurchaseInformationComponent } from "./pages/purchase-information/purchase-information.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { NovoCPlusForteComponent } from "./pages/products/novo-c-plus-forte/novo-c-plus-forte.component";
import { NovoCKomplexForteComponent } from "./pages/products/novo-c-komplex-forte/novo-c-komplex-forte.component";
import { NovoCKidsComponent } from "./pages/products/novo-c-kids/novo-c-kids.component";
import { NovoCPlusLiquidComponent } from "./pages/products/novo-c-plus-liquid/novo-c-plus-liquid.component";
import { ElderBerryComponent } from "./pages/ingredients/elder-berry/elder-berry.component";
import { ThankYouComponent } from "./pages/thank-you/thank-you.component";
import { NovoCMultivitaminComponent } from './pages/products/novo-c-multivitamin/novo-c-multivitamin.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    MainPageComponent,
    ProductsComponent,
    NovoCPlusComponent,
    NovoCKomplexComponent,
    NovoCImmunComponent,
    LiposomalTechnologyComponent,
    CVitaminComponent,
    DVitaminComponent,
    ZincComponent,
    RosehipsComponent,
    CitrusBioflavonoidComponent,
    PharmacyFinderComponent,
    AbsorptionComponent,
    PurchaseInformationComponent,
    ContactComponent,
    NovoCPlusForteComponent,
    NovoCKomplexForteComponent,
    NovoCKidsComponent,
    NovoCPlusLiquidComponent,
    ElderBerryComponent,
    ThankYouComponent,
    NovoCMultivitaminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
