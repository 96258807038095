<section id="novo-c-kids" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> kids</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-plus-img"
          src="/assets/images/pages/novo-c-kids/novo-c-kids.png"
          alt="Novo C kids"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> kids sa odporúča užívať na:</h2>
        <ul>
          <li>správne fungovanie imunitného systému</li>
          <li>na zníženie stavu únavy a vyčerpanosti</li>
          <li>normálne fungovanie nervového systému</li>
          <li>skrátenie dĺžky ochorenia v prípade prechladnutia</li>
          <li>udržanie normálnej psychickej pohody</li>
          <li>
            normálne fungovanie metabolických procesov produkujúcich energiu
          </li>
          <li>
            na podporu tvorby kolagénu a tým pre udržanie normálneho stavu a
            funkcie pokožky, zubov a ďasien, kostí a chrupaviek, ako aj krvných
            ciev
          </li>
          <li>zvýšenie absorpcie železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- <section id="maszokas-gyerekek">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="table-div">
          <h2>HATÓANYAG 1ML FOLYADÉKBAN</h2>
          <table>
            <tbody>
              <tr>
                <th>C-vitamin</th>
                <td>50 mg</td>
                <td>63%*</td>
              </tr>
              <tr>
                <th>Bodzabogyó kivonat</th>
                <td>0,26 mg</td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-div">
          <h2>HATÓANYAG 2ML FOLYADÉKBAN</h2>
          <table>
            <tbody>
              <tr>
                <th>C-vitamin</th>
                <td>100 mg</td>
                <td>125%*</td>
              </tr>
              <tr>
                <th>Bodzabogyó kivonat</th>
                <td>0,53 mg</td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-div">
          <h2>HATÓANYAG 4ML FOLYADÉKBAN</h2>
          <table>
            <tbody>
              <tr>
                <th>C-vitamin</th>
                <td>200 mg</td>
                <td>250%*</td>
              </tr>
              <tr>
                <th>Bodzabogyó kivonat</th>
                <td>1,05 mg</td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>* NRV% = napi beviteli referencia érték %-a felnőttek számára</p>
        <p>NA=nincs adat</p>
        <p>
          <strong
            >Összetevők: Tiszta víz, Növényi glicerin, Vitamin: C-vitamin:
            (Na-L-aszkorbát), Savanyúságot szabályzó anyag: tejsav, Emulgeáló
            szer: szójalecitin, Aroma: málna aroma, Édesítőszer: Steviol
            glicoside; Antioxidáns: Bodzabogyó kivonat (Polifenol 25 %;
            Antociánok 5 %).</strong
          >
        </p>
        <a href="" class="btn">Vásárlási információk</a>
      </div>
      <div class="col-lg-6">
        <img
          src="/assets/images/pages/novo-c-kids/maszokas-gyerekek.jpg"
          alt="Kids"
        />
      </div>
    </div>
  </div>
</section> -->

<section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="content">
          <p>
            Novo C<sup>&reg;</sup> kids vitamínu C je tekutý doplnok výživy
            obsahujúci lipozomálny vitamín C.
          </p>
          <p>
            1000 mg vitamínu C v 5 ml dennej dávky. Určené pre ľudí so zvýšenými
            požiadavkami na vitamín C.
          </p>
          <a [routerLink]="['/liposomalna-technologia']" class="btn">Ďalšie</a>
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="/assets/images/pages/novo-c-plus/narancs.png"
          alt="C-vitamin"
        />
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7">
        <div class="table-div">
          <h2>Účinná látka a * NRV% v odporúčanej dennej dávke</h2>
          <table>
            <tbody>
              <tr>
                <th colspan="3">V 1 ml</th>
              </tr>
              <tr>
                <td>Účinná látka</td>
                <td>Účinná látka</td>
                <td>*NRV%</td>
              </tr>
              <tr>
                <td>Vitamín C</td>
                <td>50 mg</td>
                <td>63%</td>
              </tr>
              <tr>
                <td>Výťažok z bazy Extrakt z černého bezu</td>
                <td>0,26 mg</td>
                <td>NA</td>
              </tr>
              <tr>
                <th colspan="3">V 2 ml</th>
              </tr>
              <tr>
                <td>Účinná látka</td>
                <td>Účinná látka</td>
                <td>*NRV%</td>
              </tr>
              <tr>
                <td>Vitamín C</td>
                <td>100 mg</td>
                <td>125%</td>
              </tr>
              <tr>
                <td>Výťažok z bazy Extrakt z černého bezu</td>
                <td>0,53 mg</td>
                <td>NA</td>
              </tr>
              <tr>
                <th colspan="3">V 4 ml</th>
              </tr>
              <tr>
                <td>Účinná látka</td>
                <td>Účinná látka</td>
                <td>*NRV%</td>
              </tr>
              <tr>
                <td>Vitamín C</td>
                <td>200 mg</td>
                <td>250%</td>
              </tr>
              <tr>
                <td>Výťažok z bazy Extrakt z černého bezu</td>
                <td>1,04 mg</td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content">
          <p>
            *NRV%= Referenčná hodnota denného príjmu pre dospelých. NA= údaje
            nie sú k dispozícii.
          </p>
          <p>
            <b>Zloženie:</b> Čistá voda, Rastlinný glycerín, Vitamín: Vitamin C:
            L-askorbát sodný, Regulátor kyslosti: kyselina mliečna, Emulgátor:
            sójový lecitín, Príchuť: malinová príchuť, Sladidlo: Steviol
            glykozid; Antioxidant: Extrakt z bazy čiernej bezu (25% polyfenolu;
            5% antokyanov).
          </p>
          <p>
            Bez cukru, bez laktózy, bezlepkový, bez konzervantov, nealkoholické,
            pro vegetariany
          </p>
          <a
            [routerLink]="['/informacie-o-nakupe']"
            routerLinkActive="router-link-active"
            class="btn"
            >INFORMÁCIE O KÚPE</a
          >
        </div>
      </div>
      <div class="col-lg-5">
        <!-- <img
          src="/assets/images/pages/novo-c-kids/novo-c-kids.png"
          alt="Novo C kids"
        /> -->
        <img
          src="/assets/images/pages/novo-c-kids/maszokas-gyerekek.jpg"
          alt="Kids"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-kids/novo-c-kids-title.png"
          alt="Novo C kids"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>Informácie o produkte:</h2>
        <p>
          <b>Novo C<sup>&reg;</sup> kids zaručuje:</b>
        </p>
        <ul>
          <li>obsah výlučne geneticky nemodifikovaných zložiek,</li>
          <li>prírodné farbivo získané z paradajkového extraktu (lykopén),</li>
          <li>vyrobené bez konzervačných látok.</li>
        </ul>
        <p>
          <b
            >Neobsahuje žiadne zbytočné prísady, ktoré by zaťažovali
            organizmus.</b
          >
        </p>
        <p>
          Moderný výrobný proces produktu zodpovedá požiadavkám správnej
          výrobnej praxe (GMP).
        </p>
        <div class="icons">
          <div class="img">
            <img src="/assets/images/pages/ikonok-cukormentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-laktozmentes.png" alt="" />
          </div>
          <div class="img">
            <img
              src="/assets/images/pages/ikonok-tartositoszermentes.png"
              alt=""
            />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-glutenmentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-alkoholmentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-vegan.png" alt="" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h2>Dávkovanie:</h2>
        <p>
          Novo C<sup>&reg;</sup> kids je tekutý doplnok výživy s malinovou
          príchuťou a lipozomálnym vitamínom C.
        </p>
        <ul>
          <li>1-3 roky 1x1 ml denne</li>
          <li>3-6 rokov 1x2 ml denne</li>
          <li>Viac ako 6 rokov 2x2 ml denne</li>
        </ul>
        <p>
          Dávkovanie pomocou dodanej dávkovacej lyžice. Užívanie kedykoľvek
          počas dňa bez riedenia alebo zmiešať s vodou alebo s džúsom. Pred
          konzumáciou poriadne pretrepte!
        </p>
        <h2>Skladovanie:</h2>
        <p>
          <b
            >Skladujte na chladnom a suchom mieste, chránené pred sálavým teplom
            a priamym slnečným žiarením.</b
          >
        </p>
        <p>
          Spotrebujte do 120 dní od otvorenia. Neprekračujte odporúčanú
          spotrebu! Doplnok výživy nenahrádza vyváženú zmiešanú stravu a zdravý
          životný štýl. Uchovávajte výrobok mimo dosahu malých detí! Výrobok sa
          vyrába v certifikovanom výrobnom závode v súlade s výrobnými
          požiadavkami EÚ.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PREČO JE Novo C<sup>&reg;</sup> kids ŠPECIÁLNE?</h2>
        <p>
          Špecializovaná starostlivosť o deti. Detský doplnok výživy Novo C<sup
            >&reg;</sup
          >
          kids obsahuje vitamín C v lipozomálnej forme. Vďaka inovatívnej
          technológii je vitamín C obklopený prírodnou fosfolipidovou vrstvou,
          ktorá tiež vytvára membrány ľudských buniek. Toto ochranné puzdro
          umožňuje vitamínu C ľahšie vstúpiť do buniek. Obsah vitamínu C v
          detskej lipozomálnej tekutej forme Novo C<sup>&reg;</sup> kids má
          vysokú absorpciu a poskytuje telu vysoké hladiny vitamínu C. Vitamín C
          prispieva:
        </p>
        <ul>
          <li>pre normálne fungovanie imunitného systému,</li>
          <li>chrániť bunky pred oxidačným stresom,</li>
          <li>na zníženie únavy a vyčerpania,</li>
          <li>pre normálne fungovanie nervového systému,</li>
          <li>pre normálne metabolické procesy produkujúce energiu.</li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2>ČO SÚ LIPOZÓMY?</h2>
        <p>
          Lipozomálny vitamín C nie je nič iné ako vitamín C a prírodné látky,
          tzv šikovná kombinácia fosfolipidov. Počas výrobného procesu je
          vitamín C zabalený do obalu podobnému bunkovej membráne a vytvára
          guľôčky a lipozómy veľkosti nano. Tento obal poskytuje ochrannú vrstvu
          pre vitamín C počas tráviaceho procesu a umožňuje mu fúziu s bunkami.
          Pomocou prietoku krvi sa dostáva kdekoľvek do tela, čo umožňuje jeho
          efektívne vstrebávanie a využitie v organizme.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>
          AKO SA ABSORBUJE LIPOZOMÁLNY VITAMÍN C Z Novo C<sup>&reg;</sup> kids?
        </h2>
        <p>
          Mäkká želatínová kapsula s vitamínom C sa rozpúšťa v žalúdku a
          uvoľňujú sa z nej lipozómy. Niektoré z nich pripojením sa na bunky
          žalúdka pomocou membránovej fúzie zvyšujú obsah vitamínu C v krvi a
          rýchlo ho dodávajú telu. Väčšina lipozómov sa dostane do tenkého
          čreva, odkiaľ poskytuje postupné a dlhotrvajúce doplnenie vitamínu C
          do orgánov a systémov. Lipozomálny roztok nám teda poskytuje vitamín C
          vo väčších množstvách, nepretržite a v kratšej dobe.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ČO JE MEMBRÁNOVÁ FÚZIA?</h2>
        <p>
          Bunky a lipozóm sú ohraničené rovnakou dvojitou lipidovou vrstvou,
          ktorá sa navzájom priťahuje.
        </p>
        <p>
          Lipozómy dodávajú liečivo do buniek membránovou fúziou, takže množstvo
          uvoľňovania nie je obmedzené.
        </p>
        <div class="img-box">
          <img src="/assets/images/pages/membran.png" alt="membran" />
        </div>
        <p>
          Takže sa nemusíme báť, že močom vylúčime veľa vitamínu C, pretože
          vitamín C sa v tele využíva a nevylučuje močom. Takto môže skutočne
          mať priaznivý vplyv na imunitný systém. A nezaťažuje to ani tráviaci
          systém: napríklad sa u vás neobjaví pálenie záhy alebo zvýšené
          močenie.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>TAK ÚČINNÝ VIT C, ŽE NIŽŠIA DÁVKA JE DOSTATOČNÁ!</h2>
        <p>
          Ďalšou výhodou vynikajúceho využitia je, že dávku je možné znížiť,
          pretože účinná látka sa dostane tam, kde skutočne potrebuje pracovať.
          Už jedna kapsula Novo C<sup>&reg;</sup> kids 300 mg denne prispieva k:
          účinnej podpore imunitného systému, znižovaniu únavy a vyčerpanosti,
          zdraviu pokožky, ďasien, kostí a chrupaviek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>MALÉ, ALE OSVEDČENÉ A VEĽMI SILNÉ!</h2>
        <p>
          Klinická štúdia z roku 2015 potvrdila, že Novo C<sup>&reg;</sup> kids
          sa absorbuje v čreve a vstupuje do obehu vo väčšej miere. Telo má teda
          počas dňa k dispozícii väčšie množstvo vitamínu C, čím sa lepšie
          prispôsobuje zvýšeným potrebám tela.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>POSKYTUJE DLHODOBÝ ÚČINOK!</h2>
        <p>Vitamín C užívajte nepretržite minimálne 12 hodín.</p>
        <p>
          V prípade lipozomálneho vitamínu C sa dá očakávať nielen dlhšie
          uvoľňovanie liečiva, ale vďaka absorpčnému mechanizmu lipozómu je
          možné získať viac výhod, pri rovnakom príjme liečiva sa dajú dosiahnuť
          vyššie plazmatické hladiny.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENÉ S PRÍRODNÝMI ZLOŽKAMI</h2>
        <p>Obsahuje prírodný šípkový extrakt a citrusové bioflavonoidy.</p>
      </div>
    </div>
  </div>
</section>

<section id="csuszdas-ar">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="table-div">
          <h2>
            DOSTUPNÉ V LEKÁRŇACH.<br />BALENIE A ODPORÚČANÁ MALOOBCHODNÁ CENA:
          </h2>
          <table>
            <tbody>
              <tr>
                <th>Novo C<sup>&reg;</sup> kids</th>
                <td>13 €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5">
        <img src="/assets/images/pages/novo-c-kids/novo-c-kids.png" alt="" />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C<sup>&reg;</sup>
            kids u zdravých dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
