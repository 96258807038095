<section id="navbar" [class.bg]="true">
  <div class="container">
    <a (click)="navigate('/')" class="navbar-brand">
      <img src="/assets/images/logo.png" alt="" />
    </a>
    <button class="toggler" (click)="toggle()">
      <img src="/assets/images/hamburger.png" alt="" />
    </button>
    <ul class="navbar-nav" [ngStyle]="{ right: navbarRightPos }">
      <button class="close-toggle" (click)="toggle()">
        <img src="/assets/images/close.png" alt="" />
      </button>
      <li
        class="nav-item"
        (mouseenter)="dropddownToVisible(0)"
        (mouseleave)="dropddownToHidden(0)"
      >
        <a
          class="nav-link dropdown-menu-link"
          (click)="navigate('/nase-produkty')"
          >NAŠE PRODUKTY</a
        >
        <button (click)="toggleDropdown(0)" class="dropdown-toggle-btn">
          <img src="/assets/images/dropdown.png" alt="" />
        </button>
        <div class="dropdown" [ngStyle]="{ display: isDropdownsVisible[0] }">
          <ul class="dropdown-list">
            <li class="dropdown-item">
              <a
                (click)="navigate('/nase-produkty/novo-c-plus')"
                class="dropdown-link"
                >Novo C plus</a
              >
            </li>
            <li class="dropdown-item">
              <a
                (click)="navigate('/nase-produkty/novo-c-plus-forte')"
                class="dropdown-link"
                >Novo C plus forte</a
              >
            </li>
            <li class="dropdown-item">
              <a
                (click)="navigate('/nase-produkty/novo-c-komplex')"
                class="dropdown-link"
                >Novo C komplex</a
              >
            </li>
            <li class="dropdown-item">
              <a
                (click)="navigate('/nase-produkty/novo-c-komplex-forte')"
                class="dropdown-link"
                >Novo C komplex forte</a
              >
            </li>
            <!-- <li class="dropdown-item">
              <a
                (click)="navigate('/nase-produkty/novo-c-plus-1000mg')"
                class="dropdown-link"
                >Novo C plus 1000 mg</a
              >
            </li> -->
            <li class="dropdown-item">
              <a
                (click)="navigate('/nase-produkty/novo-c-kids')"
                class="dropdown-link"
                >Novo C kids</a
              >
            </li>
            <li class="dropdown-item">
              <a
                (click)="navigate('/nase-produkty/novo-c-multivitamin')"
                class="dropdown-link"
                >Novo C multivitamin</a
              >
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="navigate('/liposomalna-technologia')"
          >LIPOSOMÁLNA TECHNOLÓGIA</a
        >
      </li>
      <li
        class="nav-item"
        (mouseenter)="dropddownToVisible(1)"
        (mouseleave)="dropddownToHidden(1)"
      >
        <a class="nav-link dropdown-menu-link">ZLOŽKY</a>
        <button (click)="toggleDropdown(1)" class="dropdown-toggle-btn">
          <img src="/assets/images/dropdown.png" alt="" />
        </button>
        <div class="dropdown" [ngStyle]="{ display: isDropdownsVisible[1] }">
          <ul class="dropdown-list">
            <li class="dropdown-item">
              <a (click)="navigate('/vitamin-c')" class="dropdown-link"
                >Vitamín C</a
              >
            </li>
            <li class="dropdown-item">
              <a (click)="navigate('/vitamin-d')" class="dropdown-link"
                >Vitamín D</a
              >
            </li>
            <li class="dropdown-item">
              <a (click)="navigate('/zinc')" class="dropdown-link">Zinok</a>
            </li>
            <li class="dropdown-item">
              <a (click)="navigate('/sipky')" class="dropdown-link">Šípky</a>
            </li>
            <li class="dropdown-item">
              <a
                (click)="navigate('/citrusovy-bioflavonoid')"
                class="dropdown-link"
                >Citrusové bioflavonoidy</a
              >
            </li>
            <li class="dropdown-item">
              <a (click)="navigate('/baza-cierna')" class="dropdown-link"
                >Baza čierna</a
              >
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a (click)="navigate('/absorpcia')" class="nav-link">ABSORPCIA</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="navigate('/informacie-o-nakupe')"
          >INFORMÁCIE O KÚPE</a
        >
      </li>
    </ul>
  </div>
</section>
