import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-novo-c-plus-forte",
  templateUrl: "./novo-c-plus-forte.component.html",
  styleUrls: ["./novo-c-plus-forte.component.scss"],
})
export class NovoCPlusForteComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle("Novo C® plus forte");
  }
}
