<section id="elder-berry" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>Baza čierna</h1>
          <img
            src="/assets/images/pages/elder-berry/elder-berry.jpeg"
            alt="Baza čierna"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Baza čierna je jednou z najobľúbenejších a najúčinnejších bylín v
            prírodnej medicíne už od staroveku.
          </p>
        </div>
        <div class="content">
          <p>
            Jej antiseptické a protizápalové vlastnosti využívali už Indiáni a z
            jej plodov sa pripravovali očné kvapky a bylinný čaj, ktorý sa
            používal na starostlivosť o pleť.
          </p>
          <p>
            Plody bazy čiernej sú bohaté na antioxidanty, antokyány a
            flavonoidy.
          </p>
          <ul>
            <li>podporuje vylučovaciu funkciu obličiek</li>
            <li>pomáha podporovať imunitný systém</li>
            <li>
              pomáha udržiavať normálnu hladinu cukru v krvi ako súčasť zdravého
              životného štýlu
            </li>
            <li>prispieva k normálnej hladine glukózy</li>
            <li>
              antioxidanty pomáhajú chrániť bunky a tkanivá pred oxidačným
              poškodením
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
