<section id="citrus-bioflavonoid" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <h1>CITRUSOVÉ BIOFLAVONOIDY</h1>
        <img
          src="/assets/images/pages/citrus-bioflavonoid/citrus-bioflavonoid.png"
          alt="citrus-bioflavonoid"
        />
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>
            Bioflavonoidy nie sú vitamíny, ale zmesi niekoľkých účinných látok,
            tj skupín zlúčenín. Nie sú nevyhnutné pre ľudské telo, ale sú to
            mimoriadne užitočné zlúčeniny.
          </p>
        </div>
        <div class="content pt-4">
          <p>
            Flavonoidy dodávajú aj žltú a oranžovú farbu citrusových plodov a
            jasné začervenanie čerešne.
          </p>
          <p>
            Vedci už identifikovali viac ako 4 000 rôznych druhov flavonoidov,
            ktoré sú rozšírené v rastlinných potravinách.
          </p>
          <p>
            Príkladmi sú diosmín, hesperidín, peridín, rutín, flavóny a
            flavonoly.
          </p>
          <h3>Niektoré účinky bioflavonoidov na telo:</h3>
          <ul>
            <li>
              Chráni vitamín C pred oxidáciou a podporuje jeho vstrebávanie v
              tele
            </li>
            <li>
              Znižuje vzlínavosť a priepustnosť pri prevencii alebo liečbe
              modrín, opuchov a krvácaní z nosa
            </li>
            <li>Znižuje hladinu cholesterolu</li>
            <li>V kombinácii s vitamínom C je vhodný na liečbu herpesu</li>
            <li>
              Pomáha bojovať proti závratom spôsobeným chorobami vnútorného ucha
            </li>
            <li>Spomaľuje proces starnutia</li>
            <li>Pomáha pri produkcii žlče</li>
            <li>Antioxidačný účinok</li>
          </ul>
          <br />
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
