import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { ProductsComponent } from "./pages/products/products.component";
import { NovoCPlusComponent } from "./pages/products/novo-c-plus/novo-c-plus.component";
import { LiposomalTechnologyComponent } from "./pages/liposomal-technology/liposomal-technology.component";
import { NovoCKomplexComponent } from "./pages/products/novo-c-komplex/novo-c-komplex.component";
import { NovoCImmunComponent } from "./pages/products/novo-c-immun/novo-c-immun.component";
import { CVitaminComponent } from "./pages/ingredients/c-vitamin/c-vitamin.component";
import { DVitaminComponent } from "./pages/ingredients/d-vitamin/d-vitamin.component";
import { ZincComponent } from "./pages/ingredients/zinc/zinc.component";
import { RosehipsComponent } from "./pages/ingredients/rosehips/rosehips.component";
import { CitrusBioflavonoidComponent } from "./pages/ingredients/citrus-bioflavonoid/citrus-bioflavonoid.component";
import { AbsorptionComponent } from "./pages/absorption/absorption.component";
import { PurchaseInformationComponent } from "./pages/purchase-information/purchase-information.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { NovoCPlusForteComponent } from "./pages/products/novo-c-plus-forte/novo-c-plus-forte.component";
import { NovoCKomplexForteComponent } from "./pages/products/novo-c-komplex-forte/novo-c-komplex-forte.component";
import { NovoCPlusLiquidComponent } from "./pages/products/novo-c-plus-liquid/novo-c-plus-liquid.component";
import { NovoCKidsComponent } from "./pages/products/novo-c-kids/novo-c-kids.component";
import { ElderBerryComponent } from "./pages/ingredients/elder-berry/elder-berry.component";
import { ThankYouComponent } from "./pages/thank-you/thank-you.component";
import { NovoCMultivitaminComponent } from "./pages/products/novo-c-multivitamin/novo-c-multivitamin.component";

const routes: Routes = [
  {
    path: "",
    component: MainPageComponent,
  },
  {
    path: "nase-produkty",
    component: ProductsComponent,
  },
  {
    path: "nase-produkty/novo-c-plus",
    component: NovoCPlusComponent,
  },
  {
    path: "nase-produkty/novo-c-komplex",
    component: NovoCKomplexComponent,
  },
  {
    path: "nase-produkty/novo-c-plus-forte",
    component: NovoCPlusForteComponent,
  },
  {
    path: "nase-produkty/novo-c-komplex-forte",
    component: NovoCKomplexForteComponent,
  },
  // {
  //   path: "nase-produkty/novo-c-plus-1000mg",
  //   component: NovoCPlusLiquidComponent,
  // },
  {
    path: "nase-produkty/novo-c-kids",
    component: NovoCKidsComponent,
  },
  {
    path: "nase-produkty/novo-c-multivitamin",
    component: NovoCMultivitaminComponent,
  },
  {
    path: "liposomalna-technologia",
    component: LiposomalTechnologyComponent,
  },
  {
    path: "vitamin-c",
    component: CVitaminComponent,
  },
  {
    path: "vitamin-d",
    component: DVitaminComponent,
  },
  {
    path: "zinc",
    component: ZincComponent,
  },
  {
    path: "sipky",
    component: RosehipsComponent,
  },
  {
    path: "citrusovy-bioflavonoid",
    component: CitrusBioflavonoidComponent,
  },
  {
    path: "baza-cierna",
    component: ElderBerryComponent,
  },
  {
    path: "absorpcia",
    component: AbsorptionComponent,
  },
  {
    path: "informacie-o-nakupe",
    component: PurchaseInformationComponent,
  },
  {
    path: "kontakt",
    component: ContactComponent,
  },
  {
    path: "dakujem",
    component: ThankYouComponent,
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
