<section id="rosehips" class="py-5">
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-md-4">
        <div class="content-title">
          <h1>Šípky</h1>
          <img
            src="/assets/images/pages/rosehips/rosehips.png"
            alt="rosehips"
          />
        </div>
      </div>
      <div class="col-md-8">
        <div class="page-short">
          <p>Šípková ruža má v tradičnej medicíne dlhoročnú tradíciu.</p>
        </div>
        <div class="content pt-4">
          <h3>
            Obsahuje veľa druhov vitamínov (vitamíny A, B1, B2, P a K), ale jeho
            najdôležitejšou účinnou látkou je prírodný vitamín C, ktorý:
          </h3>
          <ul>
            <li>podieľa sa na regulácii oxidačných procesov,</li>
            <li>posilňuje steny krvných ciev,</li>
            <li>posilňuje spojivové tkanivo,</li>
            <li>
              zvyšuje obranyschopnosť tela a odolnosť proti infekciám a
              chorobám.
            </li>
          </ul>
          <br />
          <p>
            Šípky by nemali byť namočené v horúcej vode, pretože vitamín C je už
            zničený okolo 50°C.
          </p>
          <p>
            V jeho jadre je vitamín E, ktorý chráni hlavne bunkové membrány
            jednotlivých buniek, čím pomáha brzdiť proces starnutia.
          </p>
          <p>
            Málokto vie, že ho môžeme nielen konzumovať vo forme čaju, sirupu a
            džemu, ale aj si z neho pripraviť víno. Na výrobu vína stojí za to
            použiť neoplodnené čerstvé ovocie, takže vitamín E sa dá využiť aj v
            tele. Pripravené víno má vitamínový účinok, mierny protizápalový
            účinok, mierne močopudný účinok.
          </p>
          <p>
            Jeho kvet ako ružová voda má osviežujúci antiseptický účinok,
            štetiny listov sú osviežujúcim a sťahujúcim bylinkovým čajom.
          </p>
          <p>
            Vďaka silnému antioxidačnému účinku šípka chráni celý náš imunitný
            systém.
          </p>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
