<section id="novo-c-plus" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> plus</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-plus-img"
          src="/assets/images/pages/main/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> plus sa odporúča užívať na:</h2>
        <ul>
          <li>správne fungovanie imunitného systému</li>
          <li>na zníženie stavu únavy a vyčerpanosti</li>
          <li>normálne fungovanie nervového systému</li>
          <li>skrátenie dĺžky ochorenia v prípade prechladnutia</li>
          <li>udržanie normálnej psychickej pohody</li>
          <li>
            normálne fungovanie metabolických procesov produkujúcich energiu
          </li>
          <li>
            na podporu tvorby kolagénu a tým pre udržanie normálneho stavu a
            funkcie pokožky, zubov a ďasien, kostí a chrupaviek, ako aj krvných
            ciev
          </li>
          <li>zvýšenie absorpcie železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="content">
          <p>
            Lipozomálny vitamín C Novo C<sup>&reg;</sup> plus je novou
            generáciou vitamínu C. Spoznáte viac ako tradičné vitamíny C a viac
            ako len dlhotrvajúci vitamín C.
          </p>
          <a
            [routerLink]="['/liposomalna-technologia']"
            routerLinkActive="router-link-active"
            class="btn"
            >Ďalšie</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="/assets/images/pages/novo-c-plus/narancs.png"
          alt="C-vitamin"
        />
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="table-div">
          <h2>Účinné látky v jednej kapsule</h2>
          <table>
            <tr>
              <th>Vitamín C</th>
              <td>300mg</td>
              <td>375%*</td>
            </tr>
            <tr>
              <th>Šípkový extrakt</th>
              <td>20mg</td>
              <td>**</td>
            </tr>
            <tr>
              <th>Citrusové bioflavonoidy</th>
              <td>20mg</td>
              <td>**</td>
            </tr>
          </table>
        </div>
        <div class="content">
          <p>
            * NRV% = referenčnej hodnoty denného príjmu pre dospelých
            <br />
            ** NRV neurčené
            <br />
            <b
              >Ďalšie zložky: želatína, nerafinovaný organický sezamový olej,
              čistená voda, zmáčadlo (glycerín), emulgátor (sójový lecitín),
              organický palmový tuk, farbivo (lykopén).</b
            >
          </p>
          <a
            [routerLink]="['/informacie-o-nakupe']"
            routerLinkActive="router-link-active"
            class="btn"
            >INFORMÁCIE O KÚPE</a
          >
        </div>
      </div>
      <div class="col-lg-6 flex-center">
        <img
          src="/assets/images/pages/main/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-plus/novo-c-plus-title.png"
          alt="Novo C plus"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>Informácie o produkte:</h2>
        <p>
          <b>Novo C<sup>&reg;</sup> plus zaručuje:</b>
        </p>
        <ul>
          <li>obsah výlučne geneticky nemodifikovaných zložiek,</li>
          <li>prírodné farbivo získané z paradajkového extraktu (lykopén),</li>
          <li>vyrobené bez konzervačných látok.</li>
        </ul>
        <p>
          <b
            >Neobsahuje žiadne zbytočné prísady, ktoré by zaťažovali
            organizmus.</b
          >
        </p>
        <p>
          Moderný výrobný proces produktu zodpovedá požiadavkám správnej
          výrobnej praxe (GMP).
        </p>
        <img
          src="/assets/images/pages/novo-c-plus/GMO-mentes.png"
          alt="GMO mentes"
          width="110px"
        />
        <img
          src="/assets/images/pages/novo-c-plus/GMO-free.png"
          alt="GMO mentes"
          width="110px"
        />
      </div>
      <div class="col-md-6">
        <h2>DOPORUČENÉ DÁVKOVANIE:</h2>
        <p>
          <b
            >Kapsuly Novo C<sup>&reg;</sup> plus sa majú užívať prednostne ráno
            a zapiť dostatočným množstvom tekutín. Odporúčaná denná dávka je 1 -
            2 kapsuly pre dospelých a 1 - 2 kapsuly pre deti od 6 rokov.
            Odporúčaná denná dávka sa môže užiť súčasne. Forma mäkkej
            želatínovej kapsuly poskytuje presné dávkovanie, pohodlné, bezpečné
            a ľahké prehltnutie.</b
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PREČO JE Novo C<sup>&reg;</sup> plus ŠPECIÁLNE?</h2>
        <p>
          Lipozomálny vitamín C Novo C<sup>&reg;</sup> plus je novou generáciou
          vitamínu C s najefektívnejšou absorpciou vitamínu C! Spoznáte viac ako
          tradičné vitamíny C a viac ako len dlhotrvajúci vitamín C.
        </p>
      </div>
    </div>
    <!-- <div class="row">
            <div class="col-md-12">
                <h2>Liposzómális technológiával készül</h2>
                <p>A liposzómális technológia a legújabb technológiai áttörések egyike, melyet az orvos-kutatók a fontos hatóanyagok megfelelő szervekhez történő eljuttatására alkalmaznak.</p>
                <p>Ez a technológia ugyanis célzott szállítást tesz lehetővé a szervezeten belül.</p>
            </div>
        </div> -->
    <div class="row">
      <div class="col-md-12">
        <h2>ČO SÚ LIPOZÓMY?</h2>
        <p>
          Lipozomálny vitamín C nie je nič iné ako vitamín C a prírodné látky,
          tzv šikovná kombinácia fosfolipidov. Počas výrobného procesu je
          vitamín C zabalený do obalu podobnému bunkovej membráne a vytvára
          guľôčky a lipozómy veľkosti nano. Tento obal poskytuje ochrannú vrstvu
          pre vitamín C počas tráviaceho procesu a umožňuje mu fúziu s bunkami.
          Pomocou prietoku krvi sa dostáva kdekoľvek do tela, čo umožňuje jeho
          efektívne vstrebávanie a využitie v organizme.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>
          AKO SA ABSORBUJE LIPOZOMÁLNY VITAMÍN C Z Novo C<sup>&reg;</sup> plus?
        </h2>
        <p>
          Mäkká želatínová kapsula s vitamínom C sa rozpúšťa v žalúdku a
          uvoľňujú sa z nej lipozómy. Niektoré z nich pripojením sa na bunky
          žalúdka pomocou membránovej fúzie zvyšujú obsah vitamínu C v krvi a
          rýchlo ho dodávajú telu. Väčšina lipozómov sa dostane do tenkého
          čreva, odkiaľ poskytuje postupné a dlhotrvajúce doplnenie vitamínu C
          do orgánov a systémov. Lipozomálny roztok nám teda poskytuje vitamín C
          vo väčších množstvách, nepretržite a v kratšej dobe.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ČO JE MEMBRÁNOVÁ FÚZIA?</h2>
        <p>
          Bunky a lipozóm sú ohraničené rovnakou dvojitou lipidovou vrstvou,
          ktorá sa navzájom priťahuje.
        </p>
        <p>
          Lipozómy dodávajú liečivo do buniek membránovou fúziou, takže množstvo
          uvoľňovania nie je obmedzené.
        </p>
        <div class="img-box">
          <img src="/assets/images/pages/membran.png" alt="membran" />
        </div>
        <p>
          Takže sa nemusíme báť, že močom vylúčime veľa vitamínu C, pretože
          vitamín C sa v tele využíva a nevylučuje močom. Takto môže skutočne
          mať priaznivý vplyv na imunitný systém. A nezaťažuje to ani tráviaci
          systém: napríklad sa u vás neobjaví pálenie záhy alebo zvýšené
          močenie.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>TAK ÚČINNÝ VIT C, ŽE NIŽŠIA DÁVKA JE DOSTATOČNÁ!</h2>
        <p>
          Ďalšou výhodou vynikajúceho využitia je, že dávku je možné znížiť,
          pretože účinná látka sa dostane tam, kde skutočne potrebuje pracovať.
          Už jedna kapsula Novo C<sup>&reg;</sup> plus 300 mg denne prispieva k:
          účinnej podpore imunitného systému, znižovaniu únavy a vyčerpanosti,
          zdraviu pokožky, ďasien, kostí a chrupaviek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>MALÉ, ALE OSVEDČENÉ A VEĽMI SILNÉ!</h2>
        <p>
          Klinická štúdia z roku 2015 potvrdila, že Novo C<sup>&reg;</sup> plus
          sa absorbuje v čreve a vstupuje do obehu vo väčšej miere. Telo má teda
          počas dňa k dispozícii väčšie množstvo vitamínu C, čím sa lepšie
          prispôsobuje zvýšeným potrebám tela.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>POSKYTUJE DLHODOBÝ ÚČINOK!</h2>
        <p>Vitamín C užívajte nepretržite minimálne 12 hodín.</p>
        <p>
          V prípade lipozomálneho vitamínu C sa dá očakávať nielen dlhšie
          uvoľňovanie liečiva, ale vďaka absorpčnému mechanizmu lipozómu je
          možné získať viac výhod, pri rovnakom príjme liečiva sa dajú dosiahnuť
          vyššie plazmatické hladiny.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENÉ S PRÍRODNÝMI ZLOŽKAMI</h2>
        <p>Obsahuje prírodný šípkový extrakt a citrusové bioflavonoidy.</p>
      </div>
    </div>
  </div>
</section>

<section id="packaging" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-7" style="align-self: center">
        <div class="table-div">
          <h2 style="margin: 0">Dostupné v lekárňach.</h2>
          <h2>Balenie a odporúčaná maloobchodná cena:</h2>
          <table>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus 30</th>
              <td>11 €</td>
            </tr>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus 60</th>
              <td>17 €</td>
            </tr>
            <tr>
              <th>Novo C<sup>&reg;</sup> plus 90</th>
              <td>22 €</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <img
          src="/assets/images/pages/main/novo-c-plus.png"
          alt="Novo C plus"
        />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C<sup>&reg;</sup>
            plus u zdravých dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
