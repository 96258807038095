<section id="novo-c-multivitamin" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> multivitamin</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-multivitamin-img"
          src="/assets/images/pages/novo-c-multivitamin/novo-c-multivitamin.webp"
          alt="Novo C multivitamin"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> multivitamin Doporučuje se pro:</h2>
        <ul>
          <li>Odporúčané</li>
          <li>
            Obsahuje 24 dôležitých vitamínov, minerálov a stopových prvkov
          </li>
          <li>
            vďaka svojmu lipozomálnemu zloženiu sa absorbuje efektívnejšie a dá
            sa lepšie použiť
          </li>
          <li>
            postupné vstrebávanie, takže 1 kapsula denne dodáva potrebné
            mikroživiny postupne počas dňa
          </li>
          <li>
            distribúcia prispôsobená potrebám Slovenska s prihliadnutím na
            nutričné potreby
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="table-div">
          <h2>Účinná látka a * NRV% v odporúčanej dennej dávke</h2>
          <table>
            <tbody>
              <tr>
                <td>BETA-GLUKÁN</td>
                <td>125 mg</td>
                <td>SO</td>
                <td>HORČÍK</td>
                <td>120 mg</td>
                <td>32%</td>
              </tr>
              <tr class="mobile">
                <td>HORČÍK</td>
                <td>120 mg</td>
                <td>32%</td>
              </tr>

              <tr>
                <td>VITAMÍN C</td>
                <td>80 mg</td>
                <td>100%</td>
                <td>Q10</td>
                <td>25 mg</td>
                <td>SO</td>
              </tr>
              <tr class="mobile">
                <td>Q10</td>
                <td>25 mg</td>
                <td>SO</td>
              </tr>

              <tr>
                <td>VITAMÍN B3</td>
                <td>16 mg</td>
                <td>100%</td>
                <td>VITAMÍN E</td>
                <td>12 mg</td>
                <td>100%</td>
              </tr>

              <tr class="mobile">
                <td>VITAMÍN E</td>
                <td>12 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>ZINOK</td>
                <td>10 mg</td>
                <td>100%</td>
                <td>Železo</td>
                <td>10 mg</td>
                <td>71%</td>
              </tr>

              <tr class="mobile">
                <td>Železo</td>
                <td>10 mg</td>
                <td>71%</td>
              </tr>
              <tr>
                <td>VITAMÍN B5</td>
                <td>6 mg</td>
                <td>100%</td>
                <td>MANGÁN</td>
                <td>2 mg</td>
                <td>100%</td>
              </tr>

              <tr class="mobile">
                <td>MANGÁN</td>
                <td>2 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>VITAMÍN B2</td>
                <td>1,4 mg</td>
                <td>100%</td>
                <td>VITAMÍN B6</td>
                <td>1,4 mg</td>
                <td>100%</td>
              </tr>

              <tr class="mobile">
                <td>VITAMÍN B6</td>
                <td>1,4 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>VITAMÍN B1</td>
                <td>1,1 mg</td>
                <td>100%</td>
                <td>MEĎ</td>
                <td>1 mg</td>
                <td>100%</td>
              </tr>

              <tr class="mobile">
                <td>MEĎ</td>
                <td>1 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>VITAMÍN A</td>
                <td>500 mg</td>
                <td>63%</td>
                <td>VITAMÍN B9</td>
                <td>200 mg</td>
                <td>100%</td>
              </tr>

              <tr class="mobile">
                <td>VITAMÍN B9</td>
                <td>200 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>JÓD</td>
                <td>100 mg</td>
                <td>67%</td>
                <td>SELÉN</td>
                <td>55 mg</td>
                <td>100%</td>
              </tr>

              <tr class="mobile">
                <td>SELÉN</td>
                <td>55 mg</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>MOLYBDÉN</td>
                <td>50 mg</td>
                <td>100%</td>
                <td>VITAMÍN B7</td>
                <td>30 mg</td>
                <td>60%</td>
              </tr>

              <tr class="mobile">
                <td>VITAMÍN B7</td>
                <td>30 mg</td>
                <td>60%</td>
              </tr>
              <tr>
                <td>VITAMÍN D3</td>
                <td>25 (1000 IU) mg</td>
                <td>500%</td>
                <td>CHRÓM</td>
                <td>25 mg</td>
                <td>63%</td>
              </tr>

              <tr class="mobile">
                <td>CHRÓM</td>
                <td>25 mg</td>
                <td>63%</td>
              </tr>
              <tr>
                <td>K2</td>
                <td>15 mg</td>
                <td>*SO</td>
                <td>VITAMÍN B12</td>
                <td>6 mg</td>
                <td>240%</td>
              </tr>

              <tr class="mobile">
                <td>VITAMÍN B12</td>
                <td>6 mg</td>
                <td>240%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content">
          <p>* NRV % = % referenčnej hodnoty denného príjmu pre dospelých</p>
          <p>** NRV nie je určené</p>
          <p>
            <b>Ďalšie zložky:</b> sójový lecitín (topcitín), nerafinovaný
            sezamový olej, včelí vosk, lykopén, betakarotén, želatína
          </p>
          <a [routerLink]="['/informacie-o-nakupe']" class="btn"
            >INFORMÁCIE O KÚPE</a
          >
        </div>
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-multivitamin/novo-c-multivitamin-title.webp"
          alt="Novo C multivitamin"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>VLASTNOSTI:</h2>
        <div class="icons">
          <div class="img">
            <img src="/assets/images/pages/ikonok-cukormentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-laktozmentes.png" alt="" />
          </div>
          <div class="img">
            <img
              src="/assets/images/pages/ikonok-tartositoszermentes.png"
              alt=""
            />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-glutenmentes.png" alt="" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h2>ODPORÚČANÉ DÁVKOVANIE::</h2>
        <p>
          Od 12 rokov 1 mäkkú želatínovú kapsulu denne, zapiť dostatočným
          množstvom tekutiny, najlepšie ráno. Neprekračujte odporúčané denné
          množstvo!
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PREČO JE NOVO C® MULTIVITAMÍN ŠPECIÁLNY?</h2>
        <p>
          Novo C multivitamín je lipozomálny multivitamín s postupným
          vstrebávaním , ktorý obsahuje 24 dôležitých vitamínov, minerálov a
          stopových prvkov.
        </p>
        <p>
          Vďaka svojmu lipozomálnemu vstrebávaniu sa absorbuje efektívnejšie a
          dokáže lepšie využiť jednotlivé zložky.
        </p>
        <p>
          Má oneskorené vstrebávanie, takže 1 kapsula denne postupne dodáva
          potrebné mikroživiny počas celého dňa.
        </p>
        <p>Obsahuje prebiotiká a Q10.</p>
        <p>
          Obsahuje zvýšenú dávku vitamínu B12, ktorý prispieva k normálnej
          tvorbe červených krviniek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENÉ Lipozomálnou technológiou.</h2>
        <p>
          Lipozomálna technológia je jedným z najnovších technologických objavov
          používaných medicínskymi výskumníkmi na dodávanie dôležitých aktívnych
          zložiek do príslušných orgánov.
        </p>
        <p>Táto technológia umožňuje cielené vstrebávanie v rámci organizmu.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ČO SÚ LIPOZÓMY?</h2>
        <p>
          Lipozomálny multivitamín nie je nič iné ako rôzne vitamíny, minerály,
          stopové prvky a prírodné látky, tzv. inteligentná kombinácia
          fosfolipidov.
        </p>
        <p>
          Počas výrobného procesu sú zložky zabalené do obalu podobného bunkovej
          membráne, čím sa vytvoria nano-guľôčky a lipozómy.
        </p>
        <p>
          Tento povlak poskytuje ochrannú vrstvu pre aktívne zložky počas
          procesu trávenia a umožňuje im splynúť s bunkami. Pomocou prietoku
          krvi sa dostane do ktorejkoľvek časti nášho tela, čím umožňuje výborné
          vstrebávanie a využitie.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>AKO SA NOVO C® MULTIVITAMÍN Vstrebáva?</h2>
        <p>
          Mäkká želatínová multivitamínová kapsula sa rozpúšťa v žalúdku a
          uvoľňujú sa lipozómy. Časť z nich sa membránovou fúziou spája s
          bunkami žalúdka, čím zvyšuje hladinu vitamínov v krvi a sprístupňuje
          ich telu rýchlo. Väčšina z nich sa dostane do tenkého čreva, odkiaľ
          zabezpečujú postupné, dlhotrvajúce zásobovanie našich orgánov.
        </p>
        <p>
          Lipozomálny roztok nám teda poskytuje potrebné mikroživiny vo väčších
          množstvách, nepretržite a kratším spôsobom.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ČO JE MEMBRÁNOVÁ FÚZIA?</h2>
        <p>
          Bunky a lipozóm sú ohraničené rovnakou lipidovou dvojvrstvou, ktorá sa
          navzájom priťahuje.
        </p>
        <p>
          Lipozómy dodávajú aktívnu zložku bunkám membránovou fúziou, takže
          množstvo dodávania nie je obmedzené.
        </p>
        <p>
          Nemusíme sa teda báť, že väčšinu vitamínu C „uvidíme“ v kúpeľni,
          pretože vitamín C sa v našom tele využije a nevylúči sa močom. Takto
          môže skutočne uplatniť svoj priaznivý vplyv na imunitný systém.
          Nezaťažuje ani tráviaci systém: nepocítite napríklad pálenie záhy či
          zvýšené nutkanie na močenie.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
