import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ContactModel } from "src/app/models/contact.model";
import { ContactService } from "src/app/services/contact.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  isLoadingBtn: boolean;
  errorMessage!: string | null;

  emailFormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email]),
    subject: new FormControl(""),
    message: new FormControl(""),
    privacyPolicy: new FormControl(false, Validators.requiredTrue),
  });

  constructor(private router: Router, private contactService: ContactService) {
    this.isLoadingBtn = false;
  }

  ngOnInit(): void {}

  onSubmit(): void {
    this.isLoadingBtn = true;
    this.errorMessage = null;

    const contact: ContactModel = {
      name: this.emailFormGroup.controls["name"].value,
      email: this.emailFormGroup.controls["email"].value,
      subject: this.emailFormGroup.controls["subject"].value,
      message: this.emailFormGroup.controls["message"].value,
      privacyPolicy: this.emailFormGroup.controls["privacyPolicy"].value,
    };

    this.contactService
      .createContact(contact)
      .then((res: any) => {
        if (res.status === "OK") {
          this.isLoadingBtn = false;
          this.router.navigate(["/dakujem"]);
        } else {
          this.errorMessage =
            'Chyba! Správu nebolo možné odoslať. Skúste to prosím znova alebo nám napíšte na <a href="mailto:info@novoc.sk>info@novoc.sk</a>!';
          this.isLoadingBtn = false;
        }
      })
      .catch((err: any) => {
        this.errorMessage =
          'Chyba! Správu nebolo možné odoslať. Skúste to prosím znova alebo nám napíšte na <a href="mailto:info@novoc.sk>info@novoc.sk</a>!';
        this.isLoadingBtn = false;
      });
  }
}
