<section id="novo-c-komplex-forte" class="py-md-5">
  <div class="container py-lg-5">
    <div class="row">
      <div class="col-md-12">
        <div id="title">
          <h1>Novo C<sup>&reg;</sup> komplex forte</h1>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-md-6">
        <img
          id="novo-c-komplex-img"
          src="/assets/images/pages/novo-c-komplex-forte/novo-c-komplex-forte.png"
          alt="Novo C komplex forte"
        />
      </div>
      <div class="col-md-6">
        <h2>Novo C<sup>&reg;</sup> komplex forte sa odporúča užívať na:</h2>
        <ul>
          <li>správne fungovanie imunitného systému</li>
          <li>na zníženie stavu únavy a vyčerpanosti</li>
          <li>normálne fungovanie nervového systému</li>
          <li>skrátenie dĺžky ochorenia v prípade prechladnutia</li>
          <li>udržanie normálnej psychickej pohody</li>
          <li>
            normálne fungovanie metabolických procesov produkujúcich energiu
          </li>
          <li>
            na podporu tvorby kolagénu a tým pre udržanie normálneho stavu a
            funkcie pokožky, zubov a ďasien, kostí a chrupaviek, ako aj krvných
            ciev
          </li>
          <li>zvýšenie absorpcie železa</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<section id="narancs" class="my-5">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="content">
          <p>
            3 v 1 imunitný komplex* Novo C<sup>&reg;</sup> komplex forte doplnok
            výživy obsahujúci lipozomálny vitamín C, vitamín D3 a zinok s
            extraktom zo šípok a citrusovými biofl avonoidmi. Určené na podporu
            imunitného systému. * Trojité zloženie Novo C<sup>&reg;</sup>
            komplex forte, vitamín C, vitamín D3 a zinok prispieva k normálnemu
            fungovaniu imunitného systému. Okrem vitamínu C, vitamínu D3 a zinku
            obsahuje produkt aj prírodný šípkový extrakt a citrusové biofl
            avonoidy. Obsahuje prírodné farbivo (lykopén) z paradajkového
            extraktu. Obsahuje prísadu na báze sóje.
          </p>
          <a [routerLink]="['/liposomalna-technologia']" class="btn">Ďalšie</a>
        </div>
      </div>
      <div class="col-md-5">
        <div class="img">
          <img
            src="/assets/images/pages/novo-c-komplex/d-vitamin.png"
            alt="D-vitamin"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<section id="ingredients" class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="table-div">
          <h2>Živiny a * NRV% v odporúčanej dennej dávke</h2>
          <table>
            <thead>
              <tr>
                <th></th>
                <th colspan="2">1 KAPSULA</th>
                <th colspan="2">2 KAPSULA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>VITAMÍN C</th>
                <td>600 mg</td>
                <td>750 %</td>
                <td>1200 mg</td>
                <td>1500 %</td>
              </tr>
              <tr>
                <th>VITAMÍN D3</th>
                <td>25 μg (1000 NE)</td>
                <td>500 %</td>
                <td>50 μg (2000 NE)</td>
                <td>1000 %</td>
              </tr>
              <tr>
                <th>ZINOK</th>
                <td>20 mg</td>
                <td>200 %</td>
                <td>40 mg</td>
                <td>400 %</td>
              </tr>
              <tr>
                <th>VÝŤAŽOK ZO ŠÍPOK</th>
                <td>25 mg</td>
                <td>***</td>
                <td>50 mg</td>
                <td>***</td>
              </tr>
              <tr>
                <th>CITRUSOVÉ BIOFLAVONOIDY</th>
                <td>20 mg</td>
                <td>***</td>
                <td>40 mg</td>
                <td>***</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content">
          <p>
            ** NRV% =% referenčnej hodnoty denného príjmu pre dospelých.<br />***
            NRV neurčené.
            <br />
          </p>
          <a
            [routerLink]="['/informacie-o-nakupe']"
            routerLinkActive="router-link-active"
            class="btn"
            >INFORMÁCIE O KÚPE</a
          >
        </div>
      </div>
      <div class="col-lg-4 flex-center">
        <img
          src="/assets/images/pages/novo-c-komplex-forte/novo-c-komplex-forte.png"
          alt="Novo C komplex forte"
        />
      </div>
    </div>
  </div>
</section>

<section id="content" class="mb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <img
          class="title"
          src="/assets/images/pages/novo-c-komplex-forte/novo-c-komplex-forte-title.png"
          alt="Novo C komplex forte"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h2>Informácie o produkte:</h2>
        <p>
          <b>Novo C<sup>&reg;</sup> komplex forte zaručuje:</b>
        </p>
        <ul>
          <li>obsah výlučne geneticky nemodifikovaných zložiek,</li>
          <li>prírodné farbivo získané z paradajkového extraktu (lykopén),</li>
          <li>výrobu bez konzervačných látok.</li>
        </ul>
        <p>
          <b
            >Neobsahuje žiadne zbytočné prísady, ktoré by zaťažovali
            organizmus.</b
          >
        </p>
        <p>
          Moderný výrobný proces produktu zodpovedá požiadavkám správnej
          výrobnej praxe (GMP).
        </p>
        <div class="icons">
          <div class="img">
            <img src="/assets/images/pages/ikonok-cukormentes.png" alt="" />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-laktozmentes.png" alt="" />
          </div>
          <div class="img">
            <img
              src="/assets/images/pages/ikonok-tartositoszermentes.png"
              alt=""
            />
          </div>
          <div class="img">
            <img src="/assets/images/pages/ikonok-glutenmentes.png" alt="" />
          </div>
          <!-- <img
            src="/assets/images/pages/novo-c-plus/GMO-mentes.png"
            alt="GMO mentes"
            width="110px"
          />
          <img
            src="/assets/images/pages/novo-c-plus/GMO-free.png"
            alt="GMO mentes"
            width="110px"
          /> -->
        </div>
      </div>
      <div class="col-md-6">
        <h2>Odporúčané dávkovanie:</h2>
        <p>
          <b
            >1 - 2 mäkké želatínové kapsuly denne pre dospelých a 1 mäkká
            želatínová kapsula pre deti od 12 rokov s dostatkom tekutín,
            najlepšie ráno. Neprekračujte odporúčaný denný príjem.</b
          >
        </p>
        <h2>Skladovanie:</h2>
        <p>
          <b
            >Skladujte na suchom mieste pri izbovej teplote, chránené pred
            priamym slnečným žiarením. Uchovávajte mimo dosahu malých detí.</b
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>PREČO JE Novo C<sup>&reg;</sup> komplex forte ŠPECIÁLNY?</h2>
        <p>
          Novo C<sup>&reg;</sup> komplex forte sa tým líši od tradičných
          receptúr. Kombinácia troch účinných látok podporujúcich imunitný
          systém - vitamín C, vitamín D3 a Zinok je vďaka inovatívnej
          technológii zabalená vo vrstve prírodného fosfolipidu - lipozóm, ktorý
          tiež vytvára membrány ľudských buniek. Tento ochranný obal umožňuje,
          že účinné látky ľahšie vstupujú do buniek.
        </p>
        <ul>
          <li>
            Vitamín C, vitamín D3 a zinok - prispievajú k normálnemu fungovaniu
            imunitného systému.
          </li>
          <li>
            Vitamín C a zinok tiež pomáhajú chrániť bunky pred oxidačným
            stresom.
          </li>
          <li>
            Vitamín C prispieva k zníženiu únavy a pomáha udržiavať normálny
            stav psychických funkcií.
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ČO SÚ LIPOZÓMY?</h2>
        <p>
          Lipozomálny vitamín C nie je nič iné ako vitamín C a prírodné látky,
          tzv šikovná kombinácia fosfolipidov. Počas výrobného procesu je
          vitamín C zabalený do obalu podobnému bunkovej membráne a vytvára
          guľôčky a lipozómy veľkosti nano. Tento obal poskytuje ochrannú vrstvu
          pre vitamín C počas tráviaceho procesu a umožňuje mu fúziu s bunkami.
          Pomocou prietoku krvi sa dostáva kdekoľvek do tela, čo umožňuje jeho
          efektívne vstrebávanie a využitie v organizme.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>
          AKO SA ABSORBUJE LIPOZOMÁLNY VITAMÍN C z Novo C<sup>&reg;</sup>
          komplex forte?
        </h2>
        <p>
          Mäkká želatínová kapsula s vitamínom C sa rozpúšťa v žalúdku a
          uvoľňujú sa z nej lipozómy. Niektoré z nich pripojením sa na bunky
          žalúdka pomocou membránovej fúzie zvyšujú obsah vitamínu C v krvi a
          rýchlo ho dodávajú telu. Väčšina lipozómov sa dostane do tenkého
          čreva, odkiaľ poskytuje retardované a dlhotrvajúce doplnenie vitamínu
          C do orgánov a systémov. Lipozomálny roztok nám teda poskytuje vitamín
          C vo väčších množstvách, nepretržite a v kratšej dobe.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ČO JE MEMBRÁNOVÁ FÚZIA?</h2>
        <p>
          Bunky a lipozóm sú ohraničené rovnakou dvojitou lipidovou vrstvou,
          ktorá sa navzájom priťahuje.
        </p>
        <p>
          Lipozómy dodávajú liečivo do buniek membránovou fúziou, takže množstvo
          uvoľňovania nie je obmedzené.
        </p>
        <div class="img-box">
          <img src="/assets/images/pages/membran.png" alt="membran" />
        </div>
        <p>
          Takže sa nemusíme báť, že močom vylúčime veľa vitamínu C, pretože
          vitamín C sa v tele využíva a nevylučuje močom. Takto môže skutočne
          mať priaznivý vplyv na imunitný systém. A nezaťažuje to ani tráviaci
          systém: napríklad sa u vás neobjaví pálenie záhy alebo zvýšené
          močenie.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>TAK ÚČINNÝ VIT C, ŽE NIŽŠIA DÁVKA JE DOSTATOČNÁ!</h2>
        <p>
          Ďalšou výhodou vynikajúceho využitia je, že dávku je možné znížiť,
          pretože účinná látka sa dostane tam, kde skutočne potrebuje pracovať.
          Už jedna kapsula Novo C<sup>&reg;</sup> komplex forte 300 mg denne
          prispieva k: účinnej podpore imunitného systému, znižovaniu únavy a
          vyčerpanosti, zdraviu pokožky, ďasien, kostí a chrupaviek.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>MALÉ, ALE OSVEDČENÉ A VEĽMI SILNÉ!</h2>
        <p>
          Klinická štúdia z roku 2015 potvrdila, že Novo C<sup>&reg;</sup>
          komplex forte sa absorbuje v čreve a vstupuje do obehu vo väčšej
          miere. Telo má teda počas dňa k dispozícii väčšie množstvo vitamínu C,
          čím sa lepšie prispôsobuje zvýšeným potrebám tela.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>POSKYTUJE DLHODOBÝ ÚČINOK!</h2>
        <p>Vitamín C užívajte nepretržite minimálne 12 hodín.</p>
        <p>
          V prípade lipozomálneho vitamínu C sa dá očakávať nielen dlhšie
          uvoľňovanie liečiva, ale vďaka absorpčnému mechanizmu lipozómu je
          možné získať viac výhod, pri rovnakom príjme liečiva sa dajú dosiahnuť
          vyššie plazmatické hladiny.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>VYROBENÉ S PRÍRODNÝMI ZLOŽKAMI</h2>
        <p>Obsahuje prírodný šípkový extrakt a citrusové bioflavonoidy.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>ÚLOHA VITAMÍNU D A ZINKU:</h2>
        <p>
          V posledných rokoch boli objavené účinky vitamínu D na imunitný systém
          - okrem mnohých ďalších prospešných vlastností. Vitamín D a zinok
          prispievajú k normálnemu fungovaniu imunitného systému. Zinok tiež
          prispieva k udržaniu normálnych duševných funkcií a ochrane buniek
          pred oxidačným stresom.
        </p>
      </div>
    </div>
  </div>
</section>

<section id="csuszdas-ar">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="table-div">
          <h2>
            DOSTUPNÉ V LEKÁRŇACH.<br />BALENIE A ODPORÚČANÁ MALOOBCHODNÁ CENA:
          </h2>
          <table>
            <tbody>
              <tr>
                <th>Novo C<sup>&reg;</sup> komplex forte</th>
                <td>23 €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5">
        <img
          src="/assets/images/pages/novo-c-komplex-forte/novo-c-komplex-forte.png"
          alt=""
        />
      </div>
    </div>
  </div>
</section>

<section id="small" class="py-4">
  <div class="container">
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C<sup>&reg;</sup>
            komplex forte u zdravých dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']" routerLinkActive="router-link-active"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
