<footer>
  <div class="container"></div>
  <div class="inside">
    <div class="container">
      <div class="col-md-4">
        <a
          [routerLink]="['/']"
          routerLinkActive="router-link-active"
          class="footer-logo"
        ></a>
      </div>
      <div class="col-md-8 info text-right relative">
        <div class="copyright">
          <ul class="footermenu">
            <li>
              <a
                href="/assets/GDPR-Penta-Pharma-SRO-2021-novocSK.pdf"
                target="_blank"
                routerLinkActive="router-link-active"
                >Zásady ochrany osobných údajov</a
              >
            </li>
            <li>
              <a
                [routerLink]="['/kontakt']"
                routerLinkActive="router-link-active"
              >
                Kontakt
              </a>
            </li>
          </ul>
          <span>Copyright &copy; 2021 - {{ getCurrentYear() }} NOVOC</span>
        </div>
      </div>
    </div>
  </div>
</footer>
