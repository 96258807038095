<section id="koszonjuk">
  <div class="container">
    <div class="wrapper">
      <div class="box">
        <h1>Ďakujem za Váš list!</h1>
        <p>
          Veľmi ďakujeme za Váš list, náš kolega Vás bude čoskoro kontaktovať!
        </p>
        <div class="text-center">
          <a routerLink="/" class="btn">Späť na hlavnú stránku</a>
        </div>
      </div>
    </div>
  </div>
</section>
