<section id="products" class="my-5">
  <div class="home-title text-center">
    <span> NAŠE PRODUKTY </span>
  </div>
  <div class="products-container">
    <div class="row">
      <div class="col-md-4">
        <div id="novo-c-multivitamin" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-multivitamin']">
            <img
              src="/assets/images/pages/main/novo-c-multivitamin.png"
              alt="Novo C multivitamin"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-multivitamin']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>multivitamin</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-multivitamin']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-plus" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus']">
            <img
              src="/assets/images/pages/main/novo-c-plus.png"
              alt="Novo C plus"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus']">Čítaj viac</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-plus-forte" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus-forte']">
            <img
              src="/assets/images/pages/main/novo-c-plus-forte.png"
              alt="Novo C plus forte"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus-forte']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus forte</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus-forte']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-komplex" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-komplex']">
            <img
              src="/assets/images/pages/main/novo-c-komplex.png"
              alt="Novo C komplex"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-komplex']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>komplex</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-komplex']">Čítaj viac</a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div id="novo-c-komplex-forte" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-komplex-forte']">
            <img
              src="/assets/images/pages/main/novo-c-komplex-forte.png"
              alt="Novo C komplex forte"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-komplex-forte']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>komplex forte</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-komplex-forte']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div id="novo-c-plus-liquid" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-plus-1000mg']">
            <img
              src="/assets/images/pages/main/novo-c-plus-1000mg.png"
              alt="Novo C plus 1000 mg"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-plus-1000mg']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>plus 1000 mg</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-plus-1000mg']"
              >Čítaj viac</a
            >
          </div>
        </div>
      </div> -->
      <div class="col-md-4">
        <div id="novo-c-kids" class="products text-center">
          <a [routerLink]="['/nase-produkty/novo-c-kids']">
            <img
              src="/assets/images/pages/main/novo-c-kids.png"
              alt="Novo C kids"
            />
          </a>
          <a
            [routerLink]="['/nase-produkty/novo-c-kids']"
            class="btn btn-products"
            >Novo C<sup>&reg;</sup> <b>kids</b></a
          >
          <div class="more">
            <a [routerLink]="['/nase-produkty/novo-c-kids']">Čítaj viac</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5 source">
      <div class="col-md-12">
        <p><small>Zdroj:</small></p>

        <p>
          <sup>1.</sup>
          <small
            >Otvorená, randomizovaná, porovnávacia štúdia na stanovenie
            biologickej dostupnosti doplnku vitamínu C Novo C Plus u zdravých
            dobrovoľníkov. SOTE 2014-2015.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>2.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. a kol. Nové zloženie perorálneho lipozomálneho vitamínu
            C: vlastnosti a biologická dostupnosť. J. Lip. Medzera
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>

        <div
          style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
        >
          <p style="margin: 0">
            <sup>3.</sup>
            <small>
              <a
                href="https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642"
                >https://www.tandfonline.com/doi/full/10.1080/08982104.2019.1630642</a
              >
            </small>
          </p>
        </div>
        <p>
          <small
            >Lukawsky M. et al. New oral liposomal vitamin C formulation:
            properties and bioavailability. J. Lip. Res.
            doi.org/10.1080/08982104.2019.1630642. 2018.</small
          >
        </p>
      </div>
    </div>
    <div class="info">
      <div class="col-xs-4 back">
        <a [routerLink]="['/']"
          ><i class="icon-chevron-left"></i>
          <span> Prejdite na hlavnú stránku</span></a
        >
      </div>
      <div class="col-xs-8 title text-right">
        <span
          >Doplnok výživy, nenahrádza zmiešanú stravu a zdravý životný
          štýl.</span
        >
      </div>
    </div>
  </div>
</section>
